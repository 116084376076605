export const GET_GC2_CONFIGURATION_REQUEST = 'App/GET_GC2_CONFIGURATION_REQUEST';
export const GET_GC2_CONFIGURATION_SUCCESS = 'App/GET_GC2_CONFIGURATION_SUCCESS';

export const CHECK_AUTHORIZATION_REQUEST = 'App/CHECK_AUTHORIZATION_REQUEST';
export const CHECK_AUTHORIZATION_SUCCESS = 'App/CHECK_AUTHORIZATION_SUCCESS';
export const CHECK_AUTHORIZATION_FAILURE = 'App/CHECK_AUTHORIZATION_FAILURE';

export const SIGN_IN_REQUEST = 'App/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'App/SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'App/SIGN_IN_FAILURE';

export const GET_DATABASES_RESET = 'App/GET_DATABASES_RESET';
export const GET_DATABASES_REQUEST = 'App/GET_DATABASES_REQUEST';
export const GET_DATABASES_SUCCESS = 'App/GET_DATABASES_SUCCESS';
export const GET_DATABASES_FAILURE = 'App/GET_DATABASES_FAILURE';

export const UPDATE_USER_REQUEST = 'App/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'App/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'App/UPDATE_USER_FAILURE';

export const UPDATE_USER_PASSWORD_SUCCESS = 'App/UPDATE_USER_PASSWORD_SUCCESS';

export const GET_SUBUSERS_REQUEST = 'App/GET_SUBUSERS_REQUEST';
export const GET_SUBUSERS_SUCCESS = 'App/GET_SUBUSERS_SUCCESS';
export const GET_SUBUSERS_FAILURE = 'App/GET_SUBUSERS_FAILURE';

export const GET_SCHEMAS_REQUEST = 'App/GET_SCHEMAS_REQUEST';
export const GET_SCHEMAS_SUCCESS = 'App/GET_SCHEMAS_SUCCESS';
export const GET_SCHEMAS_FAILURE = 'App/GET_SCHEMAS_FAILURE';

export const PROCESS_REQUEST = 'App/PROCESS_REQUEST';
export const PROCESS_SUCCESS = 'App/PROCESS_SUCCESS';
export const PROCESS_FAILURE = 'App/PROCESS_FAILURE';
export const PROCESS_RESET = 'App/PROCESS_RESET';

export const CREATE_UPDATE_USER_RESET = 'App/CREATE_UPDATE_USER_RESET';

export const SIGN_OUT = 'App/SIGN_OUT';
